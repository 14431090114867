<template>
  <div class="view-receipt-display">
    <div class="container">
      <div class="receipt-header">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3>ใบเสร็จรับเงิน</h3>
          <div class="d-flex align-items-center">
            <i
              id="receipt-setting-gear"
              class="fas fa-gear mr-3"
              style="cursor: pointer"
            ></i>
          </div>
        </div>
        <!-- Settings popover -->
        <b-popover
          target="receipt-setting-gear"
          triggers="click"
          placement="bottom"
          title="แสดงคอลัมน์"
        >
          <b-checkbox
            v-for="field in displayFields"
            :key="field.key"
            v-model="field.visible"
            switch
            @change="saveSetting"
          >
            {{ field.label }}
          </b-checkbox>
        </b-popover>
      </div>

      <!-- Tabs -->
      <b-tabs v-model="tabIndex" card justified>
        <b-tab title="ภาษาไทย">
          <ReceiptPreview
            v-if="isDataReady"
            :ref="tabRefs[0]"
            :lang="locale[0]"
            :mode="mode"
            :receiptData="receipt"
            :patientData="patient"
            :branchData="branchData"
            :displayKeys="displayKeys"
          />
        </b-tab>
        <b-tab title="English">
          <ReceiptPreview
            v-if="isDataReady"
            :ref="tabRefs[1]"
            :lang="locale[1]"
            :mode="mode"
            :receiptData="receipt"
            :patientData="patient"
            :branchData="branchData"
            :displayKeys="displayKeys"
          />
        </b-tab>
      </b-tabs>
    </div>

    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import ReceiptPreview from "@/components/ReceiptPreview";
import Loading from "@/components/Loading";
import { mapActions } from "vuex";
import isEmpty from "lodash/isEmpty";
import { eventBus } from "@/main";

export default {
  name: "RootReceiptDisplay",
  components: {
    ReceiptPreview,
    Loading,
  },
  props: {
    hashRef: {
      type: String,
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
    // If you already have the data and want to pass it directly
    initialReceiptData: {
      type: Object,
      default: null,
    },
    initialPatientData: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "copy", // Options: copy, cancel, original
    },
  },
  data() {
    return {
      isLoading: false,
      isDataReady: false,

      tabIndex: 0,
      tabRefs: ["ReceiptTh", "ReceiptEn"],
      locale: ["th", "en"],
      receipt: this.initialReceiptData || {},
      patient: this.initialPatientData || {},
      branchData: null,

      displayFields: [
        {
          key: "doctor",
          label: "แพทย์",
          visible: true,
        },
        {
          key: "quantity",
          label: "จำนวน",
          visible: true,
        },
        {
          key: "price",
          label: "ราคา",
          visible: true,
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          visible: true,
        },
      ],
      displayKeys: ["doctor", "quantity", "price", "finalDiscount"],
    };
  },
  created() {
    this.loadSetting();

    // If IDs are provided and no initial data, fetch the data
    if (
      this.hashRef &&
      (isEmpty(this.initialReceiptData) || isEmpty(this.initialPatientData))
    ) {
      this.fetchReceiptData();
    }
  },
  methods: {
    ...mapActions({
      fetchReceiptByHash: "moduleReceipt/fetchReceiptByHash",
    }),

    async fetchReceiptData() {
      if (!this.hashRef) return;

      this.isLoading = true;
      this.isDataReady = false;

      try {
        // Only fetch the receipt - it already contains patient and branch data
        const receiptRes = await this.fetchReceiptByHash({
          id: this.hashRef,
        });

        if (receiptRes.status === 200 && receiptRes.data) {
          const receiptData = receiptRes.data;
          this.validateAndFixNumericFields(receiptData);

          // Store the receipt data
          this.receipt = receiptData;

          // Store branch data if available in the receipt response
          if (receiptData.branch) {
            this.branchData = receiptData.branch;
          }

          // Use patient data directly from the receipt
          if (receiptData.patient && receiptData.patient.id) {
            this.patient = receiptData.patient;
            this.isDataReady = true;
          } else {
            console.error(
              "No patient data available in receipt and no patientId provided"
            );
            eventBus.$emit("alertSwal", {
              title: "ระบบขัดข้อง",
              message: "ไม่พบข้อมูลผู้ป่วย",
              icon: "error",
            });
          }
        } else {
          console.error("Failed to fetch receipt data");
          eventBus.$emit("alertSwal", {
            title: "ระบบขัดข้อง",
            message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching receipt data:", error);
        eventBus.$emit("alertSwal", {
          title: "ระบบขัดข้อง",
          message: "เกิดข้อผิดพลาดในการเรียกข้อมูล",
          icon: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    validateAndFixNumericFields(receiptData) {
      // Fix main numeric fields
      const numericFields = [
        "amount",
        "paid",
        "overdue",
        "price",
        "finalDiscount",
      ];

      numericFields.forEach(field => {
        if (receiptData[field] !== undefined) {
          // Convert to number if it's a string or fix if it's invalid
          if (
            typeof receiptData[field] !== "number" ||
            isNaN(receiptData[field])
          ) {
            const parsed = parseFloat(receiptData[field]);
            receiptData[field] = isNaN(parsed) ? 0 : parsed;
          }
        }
      });
    },

    // Column display settings management
    saveSetting() {
      this.$nextTick(() => {
        this.displayKeys = this.getKeys();
        localStorage.setItem(
          "receiptDisplayKeys",
          JSON.stringify(this.displayKeys)
        );
      });
    },

    loadSetting() {
      const savedKeys = localStorage.getItem("receiptDisplayKeys");
      if (savedKeys) {
        this.displayKeys = JSON.parse(savedKeys);

        // Update checkboxes to match saved settings
        this.displayFields.forEach(field => {
          field.visible = this.displayKeys.includes(field.key);
        });
      }
    },

    getKeys() {
      return this.displayFields
        .filter(field => field.visible)
        .map(field => field.key);
    },
  },
};
</script>

<style scoped>
.view-receipt-display {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  padding: 10px 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 850px;
  max-width: none;
  transform-origin: top center;
}

.receipt-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

/* Responsive scaling for different screen sizes */
@media (max-width: 900px) {
  .container {
    transform: scale(0.9);
  }
}

@media (max-width: 800px) {
  .container {
    transform: scale(0.8);
  }
}

@media (max-width: 700px) {
  .container {
    transform: scale(0.7);
  }
}

@media (max-width: 600px) {
  .container {
    transform: scale(0.6);
  }
}

@media (max-width: 500px) {
  .container {
    transform: scale(0.5);
  }
}

@media (max-width: 400px) {
  .container {
    transform: scale(0.4);
  }
}

@media (max-width: 320px) {
  .container {
    transform: scale(0.35);
  }
}
</style>