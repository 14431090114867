import axios from "axios";
import { eventBus } from "@/main";

const moduleReceipt = {
  namespaced: true,
  state: {
    receiptList: [],
    receiptTotalNo: null
  },
  mutations: {
    setReceiptList(state, data) {
      state.receiptList = data ? data : [];
    },
    setReceiptScrollList(state, data) {
      state.receiptList = state.receiptList.concat(data);
    },
    setReceiptTotalNo(state, data) {
      state.receiptTotalNo = data;
    },
    cancelReceipt(state, id) {
      var receipt = state.receiptList.find(function(receipt) {
        return receipt.id == id;
      });
      if (receipt) {
        receipt.cancel = true;
      }
    }
  },
  actions: {
    createReceipt({ commit }, payload) {
      return (
        axios
          .post(
            `/api/receipt/${payload.clinicUrl}/${payload.branchUrl}`,
            payload.data
          )
          // return axios
          //   .post(
          //     `/api/receipt/${payload.clinicUrl}/${payload.branchUrl}`,
          //     payload.data
          //   )
          .then(res => {
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    issueReprint({ commit }, payload) {
      return axios
        .post(
          `/api/receipt/issueReprint/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )

        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    cancelReceipt({ commit }, payload) {
      return (
        axios
          .put(
            `/api/receipt/cancel/${payload.clinicUrl}/${payload.branchUrl}`,
            payload.data
          )
          // .post(
          //   `/api/receipt/cancel/${payload.clinicUrl}/${payload.branchUrl}`,
          //   payload.data
          // )
          .then(res => {
            commit("cancelReceipt", payload.data.id);
            return res;
          })
          .catch(err => {
            if (err.response.status === 403) {
              throw err;
            }
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    fetchReceiptById({ commit }, payload) {
      return axios
        .get(
          `/api/reprint/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
          // `/api/receipt/reprint/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchReceiptByHash({ commit }, payload) {
      return axios
        .get(`/api/reprint/${payload.id}`)
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    fetchReceiptList({ commit }, payload) {
      commit("setReceiptList", []);

      let { offset, limit, search, startDt, endDt, status } = payload;
      return axios
        .get(`/api/receipt/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            offset,
            limit,
            keyword: search,
            startDt,
            endDt,
            status
          }
        })
        .then(res => {
          commit("setReceiptList", res.data.receipts);
          commit("setReceiptTotalNo", res.data.pagination.total);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    fetchReceiptScrollList({ commit }, payload) {
      let { offset, limit, search, dateFrom, dateTo, status } = payload;
      return axios
        .get(`/api/receipt/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            offset,
            limit,
            keyword: search,
            dateFrom,
            dateTo,
            status
          }
        })
        .then(res => {
          commit("setReceiptScrollList", res.data.receipts);
          commit("setReceiptTotalNo", res.data.pagination.total);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //--------- Receipt State ---------//
    cancelStateReceipt({ commit }, id) {
      commit("cancelReceipt", id);
    },

    checkCreateReceipt({ commit }, payload) {
      let searchUrl = `/api/checkCreateReceipt/${payload.clinicUrl}/${payload.branchUrl}/${payload.appointmentId}`;
      return axios
        .get(searchUrl)
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    }
  },
  getters: {
    getReceiptList: state => {
      return state.receiptList;
    },
    getReceiptTotalNo: state => {
      return state.receiptTotalNo;
    }
  }
};
export default moduleReceipt;
